<template>
  <div>
    <section class="content">
      <div class="left">
        <div class="text">
          <span v-html="page.main_text"></span>

          <h3 v-if="settings">
            <font-awesome-icon class="icon" icon="phone" />
            {{ settings.telephone }}
            <br />
            <a :href="'mailto:' + settings.email">
              <font-awesome-icon class="icon" icon="envelope" />
              {{ settings.email }}
            </a>
          </h3>
          <p v-if="settings && settings.address">{{ settings.address }}</p>
          <h2>Send a Message</h2>
          <div class="form">
            <div class="form-row">
              <label class="form-label">Name</label>
              <input class="form-text" type="text" v-model="form.name" />
            </div>
            <div class="form-row">
              <label class="form-label">Collar Number</label>
              <input class="form-text" type="text" v-model="form.collar" />
            </div>
            <div class="form-row">
              <label class="form-label">Email</label>
              <input class="form-text" type="email" v-model="form.email" />
            </div>
            <div class="form-row">
              <label class="form-label">Telephone</label>
              <input class="form-text" type="text" v-model="form.tel" />
            </div>
            <div class="form-row">
              <label class="form-label">Message</label>
              <textarea class="form-text" v-model="form.message"></textarea>
            </div>
            <div class="form-error" v-show="errormessage">{{ errormessage }}</div>
            <button @click="sendEmail" class="button">SUBMIT</button>
            <div class="form-success" v-show="emailsuccess">Thanks, your request has been sent!</div>
          </div>
        </div>
      </div>
    </section>
    <iframe
      allowfullscreen
      class="map"
      frameborder="0"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2378.129585864197!2d-1.4208098839125123!3d53.412508577454105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879778f2372e8f3%3A0x64e3ca89e7a4cc81!2sMeadow+Court%2C+Amos+Rd%2C+Sheffield%2C+South+Yorkshire+S9+1BX!5e0!3m2!1sen!2suk!4v1458045567949"
    ></iframe>
  </div>
</template>

<script>
import api from "@/services/api"

export default {
  data: function() {
    return {
      emailsuccess: false,
      errormessage: "",
      form: {},
      initial_values: {}
    }
  },
  metaInfo() {
    return {
      title: this.$store.state.page.title || "South Yorkshire Police Federation",
      meta: [
        { name: "description", content: this.$store.state.page.meta_desc },
        { name: "keywords", content: this.$store.state.page.meta_keywords }
      ]
    }
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.initial_values))
    api.getPageByURL(this.$route.path)
  },
  computed: {
    settings() {
      return this.$store.state.settings
    },
    page() {
      return this.$store.state.page
    }
  },
  methods: {
    sendEmail: function() {
      this.emailsuccess = false
      this.errormessage = ""

      if (!this.form.name || !this.form.email || !this.form.message) {
        this.errormessage = "Please fill in all required fields, thanks!"
        return false
      }

      var content = `The following message has been sent from the website:<br /><br />`

      if (this.form.name) content += `Name: ${this.form.name}<br />`
      if (this.form.collar) content += `Collar Number: ${this.form.collar}<br />`
      if (this.form.email) content += `Email: ${this.form.email}<br />`
      if (this.form.tel) content += `Telephone: ${this.form.tel}<br />`
      if (this.form.message) content += `Message: ${this.form.message}<br />`

      api
        .sendEmail("Message from the website", content)
        .then(() => {
          this.emailsuccess = true
          this.form = JSON.parse(JSON.stringify(this.initial_values))
        })
        .catch(() => {
          this.errormessage = "There has been an error submitting your message"
          this.emailerror = true
        })
    }
  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 45rem;
}
h3 {
  padding-top: 2rem;
  color: #203165;
}
h3 a {
  text-decoration: none;
  color: #203165;
}
h2 {
  margin-top: 3rem;
}
h3 svg {
  margin-right: 1rem;
  color: #db411d;
}

@media screen and (min-width: 1050px) {
  .left {
    width: 55%;
  }
  .map {
    position: fixed;
    right: 0;
    top: 0;
    width: 35%;
    height: 100vh;
    margin: 0;
    border-radius: 0;
  }
}
</style>
